.rcs-roller {
  --rcs-roller-ratio: calc(var(--rcs-roller-size) / 64);
  display: inline-block;
  position: relative;
  width: var(--rcs-roller-size);
  height: var(--rcs-roller-size);
}

.rcs-roller div {
  animation: rcs-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: calc(32 * var(--rcs-roller-ratio))
    calc(32 * var(--rcs-roller-ratio));
}

.rcs-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: calc(6 * var(--rcs-roller-ratio));
  height: calc(6 * var(--rcs-roller-ratio));
  border-radius: 50%;
  background: var(--rcs-roller-color);
  margin: calc(-3 * var(--rcs-roller-ratio)) 0 0
    calc(-3 * var(--rcs-roller-ratio));
}

.rcs-roller div:nth-child(1) {
  animation-delay: -0.036s;
}

.rcs-roller div:nth-child(1):after {
  top: calc(50 * var(--rcs-roller-ratio));
  left: calc(50 * var(--rcs-roller-ratio));
}

.rcs-roller div:nth-child(2) {
  animation-delay: -0.072s;
}

.rcs-roller div:nth-child(2):after {
  top: calc(54 * var(--rcs-roller-ratio));
  left: calc(45 * var(--rcs-roller-ratio));
}

.rcs-roller div:nth-child(3) {
  animation-delay: -0.108s;
}

.rcs-roller div:nth-child(3):after {
  top: calc(57 * var(--rcs-roller-ratio));
  left: calc(39 * var(--rcs-roller-ratio));
}

.rcs-roller div:nth-child(4) {
  animation-delay: -0.144s;
}

.rcs-roller div:nth-child(4):after {
  top: calc(58 * var(--rcs-roller-ratio));
  left: calc(32 * var(--rcs-roller-ratio));
}

.rcs-roller div:nth-child(5) {
  animation-delay: -0.18s;
}

.rcs-roller div:nth-child(5):after {
  top: calc(57 * var(--rcs-roller-ratio));
  left: calc(25 * var(--rcs-roller-ratio));
}
.rcs-roller div:nth-child(6) {
  animation-delay: -0.216s;
}

.rcs-roller div:nth-child(6):after {
  top: calc(54 * var(--rcs-roller-ratio));
  left: calc(19 * var(--rcs-roller-ratio));
}

.rcs-roller div:nth-child(7) {
  animation-delay: -0.252s;
}

.rcs-roller div:nth-child(7):after {
  top: calc(50 * var(--rcs-roller-ratio));
  left: calc(14 * var(--rcs-roller-ratio));
}

.rcs-roller div:nth-child(8) {
  animation-delay: -0.288s;
}

.rcs-roller div:nth-child(8):after {
  top: calc(45 * var(--rcs-roller-ratio));
  left: calc(10 * var(--rcs-roller-ratio));
}

@keyframes rcs-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
